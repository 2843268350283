import React, { Component } from 'react';
import Hamburger from '../image/hamburger-menu32.png';

export default class Navbar extends Component{
    myFunction() {
        var mobileNav = document.getElementById("mobile-nav");

        if (mobileNav.style.display === "block") {
          mobileNav.style.display = "none";
       }else {
          mobileNav.style.display = "block";
        }     
      }
      handleClick(e){
          e.preventDefault();
          this.myFunction();
      }
    render(){
        function checkHamburger(){
            var mobileNav = document.getElementById("mobile-nav");
            if(window.innerWidth > 720){
                mobileNav.style.display = "none";
            }
        }
        window.addEventListener('resize', checkHamburger);
        return(
            <div id="nav-wrapper">
                <nav>  
                <a href="#!" onClick={this.handleClick.bind(this)}> 
                        <img alt="" className="hamburger-menu" src={Hamburger}></img>
                    </a>
                    <li className="logo-name">JORDAN COLLINGS</li> 
                    <ul>                      
                        <div id="main-nav"> 
                            <li className="Nav-item"><a href="#about-projects" className="Nav-link" >PROJECTS</a></li>
                            <li className="Nav-item"><a href="#skills" className="Nav-link">SKILLS</a></li>
                            <li className="Nav-item"><a href="#contact-section" className="Nav-link" >CONTACT</a></li>
                        </div> 
                        <div id="mobile-nav"> 
                            <li className="mobile-nav-item"><a href="#about-projects" className="Nav-link" >PROJECTS</a></li>
                            <li className="mobile-nav-item"><a href="#skills" className="Nav-link">SKILLS</a></li>
                            <li className="mobile-nav-item"><a href="#contact-section" className="Nav-link" >CONTACT</a></li>
                        </div> 
                    </ul>
                 </nav>
            </div>
        );
    }
}