import './App.scss';
import Footer from './components/footer';
import Navbar from './components/navbar';
import Projects from './components/projects';
import Skills from './components/skills';
import About from './components/about-me';
import Header from './components/header';
import Contact from './components/contact';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <About />
      <Projects />
      <Skills />
      <Contact />
      <Footer/>
    </div>
  );
}
export default App;
