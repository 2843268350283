import React, { Component } from 'react';
import axios from 'axios';

export default class Contact extends Component{
    constructor(props){
        super(props);
        this.state = {
            name: '',
            email: '',
            message: ''
        }
    }

    handleSubmit(e){
        e.preventDefault();

        axios({
            method: "POST",
            url: "https://jordancollings.co.uk/send",
            data: this.state
        }).then((response) =>{
            if (response.data.status === 'success'){
                alert("Thank you for your message.");
                this.resetForm()
            } else if(response.data.status === 'fail'){
                alert("Message failed to send.")
            }
        })
    }

    resetForm(){
        this.setState({name: "", email: "", message: ""})
    }

    render(){
        return(
            <section id="contact-section">
                <div className="contact-wrapper">
                    <div className="contact">
                        <h1 className="white large-font">Contact</h1>
                            <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="post">
                                <input type="text" placeholder="Your Name" name="name" value={this.state.name} onChange={this.onNameChange.bind(this)}></input><br/>
                                <input type="email" placeholder="Email" name="email" value={this.state.email} onChange={this.onEmailChange.bind(this)}></input><br/>
                                <textarea placeholder="Your Message" type="text" name="message" value={this.state.message} onChange={this.onMessageChange.bind(this)}> </textarea>
                                <input className="button" type="submit" id="submit"></input>
                            </form>
                    </div>
                </div>
            </section>
        );
    }
    onNameChange(event){
        this.setState({name: event.target.value})
    }

    onEmailChange(event){
        this.setState({email: event.target.value})
    }

    onMessageChange(event){
        this.setState({message: event.target.value})
    }

}