import { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";

class Skills extends Component{
    render(){
        return(
            <section id="skills">
                <div className="skill-wrapper">
                    <h1 className="white large-font">Skills</h1>
                    <div className="skill-bar-container">
                        <span className="skill-bar-title">HTML5</span>
                        <span className="skill-value">100%</span>
                        <ScrollAnimation animateIn="progress-animation-skill1"><span id="html-bar" className="skill-bar skill-1"></span></ScrollAnimation>
                        <span className="skill-bar-title">CSS</span>
                        <span className="skill-value">80%</span>
                        <span className="skill-bar skill-2"></span>


                        <span className="skill-bar-title">WordPress</span>
                        <span className="skill-value">75%</span>
                        <span className="skill-bar skill-3"></span>

                        <span className="skill-bar-title">Javascript</span>
                        <span className="skill-value">60%</span>
                        <span className="skill-bar skill-4"></span>


                        <span className="skill-bar-title">React</span>
                        <span className="skill-value">30%</span>
                        <span className="skill-bar skill-5"></span>
                    </div>
                    <div id="skill-image-drawn"></div>
                </div>
            </section>
        );
    }
}
export default Skills;