import React, { Component } from 'react';
import LinkedinIcon from '../icon/linkedin-white-32.png';
import GithubIcon from '../icon/github-white-32.png';

export default class Footer extends Component {
    render(){
        return(
            <div className="footer-wrapper">
                <footer>  
                <div className="footer-logo">
                    <h1 className="logo-name-footer">JORDAN COLLINGS</h1>  
                </div> 
                <div style={{paddingTop: "20px", display:"inline-block", width:"50%"}}>
                <a target="_blank"  rel="noreferrer" href={"https://www.linkedin.com/in/jordan-collings-4a0877149/"}>
                <img src={LinkedinIcon} alt="Linkedin Profile"></img>
                </a>
                <a target="_blank"  rel="noreferrer" href={"https://github.com/jordancollings"}>
                <img style={{marginLeft: "10px"}}src={GithubIcon} alt="Github Profile"></img>
                </a> 
                </div>
                        <p>	&#169; 2021 | <strong> Jordan Collings</strong></p>
                </footer>
            </div>
        );
    }
  }
