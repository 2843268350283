import React, { Component } from 'react';
import man from '../man-icon.svg';
import Arrow from '../icon/down-arrow-white-64.png'

export default class Header extends Component{
    render(){
        return(
                <div className="header-wrapper">
                    <header className="App-header">
                        <h1 className="main-title">Web Developer</h1>
                        <h2 className="subtitle">I build fast, responsive websites using WordPress and React.</h2>
                        <img src={man} className="App-logo" alt="logo" />
                        <p>Jordan Collings</p>
                       <a href="#about-projects"> <img src={Arrow} className="" alt="logo" /></a>
                    </header>
                </div>
        );
    } 
}