import React, { Component } from 'react';
import Wave from '../image/about-wave.svg';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";

export default class About extends Component{
    render(){
        return(
            <section id="about-projects" className="about-section">
            <h1 className="white large-font">Hi, I'm Jordan.</h1>
            <p id="about-paragraph" className="white">
                A web developer from Torquay, United Kingdom.<br></br> So far during my web development career I have worked for Cleverfox Design, a marketing and design agency in Newton Abbot whilst also pursuing freelance projects.
            </p>
            <ScrollAnimation animateIn="animate__shakeX" duration={4} animateOnce={true}><img className="wave" alt="" src={Wave}></img></ScrollAnimation>
            </section>   
        );
     }
}


