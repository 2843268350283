import React, { Component } from 'react';
import Eventine from '../image/uo-eventine.png';
import PortfolioImage from '../image/website-image.png';
import Uni from '../image/university-project.png';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";

class Projects extends Component{
    render(){
        return(
            <div id="projects">
                <ScrollAnimation animateIn="animate__bounceInRight" animateOnce={true}><h1 className="white large-font project-header" style={{marginTop:"0px", marginBottom:"50px"}}>Projects</h1></ScrollAnimation>
                <div id="projects-wrapper">
                    <div className="projects-item">
                        <a target="_blank" href="https://uoeventine.net"><img alt="Website projects 1 - UO Eventine" className="projects-image" src={Eventine}></img></a>
                            <a target="_blank" className="projects-link" href="https://uoeventine.net">UO Eventine</a>
                    </div>
                    <div className="projects-item">
                         <a target="_blank" href="#!"><img alt="Website projects 2 - This Website!" className="projects-image" src={PortfolioImage}></img></a>
                            <a target="_blank" className="projects-link" href="#!">This Website!</a>
                    </div>
                    <div className="projects-item"> 
                        <img alt="Website projects 3 - University Project" className="projects-image" src={Uni} style={{maxWidth:"421.6px"}}></img>
                            <p className="uni-link">University Project</p>
                            <p className="uni-link">(Not currently hosted)</p>
                        </div>
                </div>
            </div>
        );
    }
}
export default Projects;